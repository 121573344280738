import { Cart } from '@/sections/shared/explore/model/Cart';
import { IClient } from '@/shared/model/client.model';
import { IStemdoer } from '@/shared/model/stemdoer.model';
import { Module } from 'vuex';

export interface CartStateStorable {
  cart: Cart;
  client: IClient;
}

export const defaultCartState: CartStateStorable = {
  cart: new Cart(),
  client: null,
};

export const cartStore: Module<CartStateStorable, any> = {
  namespaced: true,
  state: {
    cart: localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : defaultCartState.cart,
    client: localStorage.getItem('clientSelected') ? JSON.parse(localStorage.getItem('clientSelected')) : defaultCartState.client,
  },
  getters: {
    cart: state => getCartFromState(state),
    cartItems: state => {
      const cart: Cart = getCartFromState(state);
      return cart.getItems();
    },
    cartTotalItems: state => {
      const cart: Cart = getCartFromState(state);
      return cart.getTotalItems();
    },
    clientSelected: state => getClientFromState(state),
  },
  mutations: {
    clearCart(state) {
      persistCartState(state, new Cart());
      persistClientState(state, null);
    },
    addStemdoer(state, stemdoer: IStemdoer) {
      const cart: Cart = getCartFromState(state);
      cart.addToCart(stemdoer);
      persistCartState(state, cart);
    },
    removeStemdoer(state, stemdoerId: string) {
      const cart: Cart = getCartFromState(state);
      cart.removeFromCart(stemdoerId);
      persistCartState(state, cart);
    },
    setClient(state, client: IClient) {
      persistClientState(state, client);
    },
  },
};

const getCartFromState = (state): Cart => {
  return Object.assign(new Cart(), state.cart);
};
const getClientFromState = (state): IClient => {
  return Object.assign({}, state.client);
};

const persistCartState = (state, cart: Cart) => {
  state.cart = cart;
  localStorage.setItem('cart', JSON.stringify(cart));
};

const persistClientState = (state, client: IClient) => {
  state.client = client;
  localStorage.setItem('clientSelected', JSON.stringify(client));
};
