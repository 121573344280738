import { defineComponent } from 'vue';
import SAvatarStemdoer from '@/components/stemdo-components/s-avatar-stemdoer/SAvatarStemdoer.vue';
import SInputNumber from '@/components/stemdo-components/s-input-number/SInputNumber.vue';

export default defineComponent({
  props: {
    stemdoer: {
      type: Object,
      required: true,
    },
    rate: {
      type: Number,
      required: true,
    },
    updateRate: {
      type: Function,
      required: true,
    },
    onDelete: {
      type: Function,
      required: false,
    },
  },
  components: {
    SAvatarStemdoer,
    SInputNumber,
  },
  setup(props) {
    const onRateChange = (newRate: number) => {
      props.updateRate(props.stemdoer.id, newRate);
    };

    const onDeleteClick = (stemdoerId: string) => {
      props.onDelete(stemdoerId);
    };

    return {
      onRateChange,
      onDeleteClick,
    };
  },
});
