import { defineComponent, computed } from 'vue';
import SAvatarStemdoer from '@/components/stemdo-components/s-avatar-stemdoer/SAvatarStemdoer.vue';
import { useI18N } from '@/plugins/i18n';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';

export default defineComponent({
  props: {
    teamRequestDisplayed: {
      type: Array,
      required: true,
    },
  },
  components: {
    SAvatarStemdoer,
  },
  setup() {
    const i18n = useI18N();
    const headers = computed(() => [
      { text: i18n.t('requests.client'), value: 'client', width: '28%' },
      { text: i18n.t('requests.status.title'), value: 'status' },
      { text: i18n.t('requests.my-team'), value: 'stemdoers', width: '13%' },
      { text: i18n.t('requests.created'), value: 'createdAt' },
      { text: i18n.t('requests.start'), value: 'initDate' },
      { text: i18n.t('requests.cost'), value: 'hourlyRate' },
      { text: '', value: 'action', width: '1%' },
    ]);

    const getStatusDetails = (status: string) => {
      let translation = '';

      switch (status) {
        case RequestStatus.ON_GOING:
          translation = i18n.t('requests.detail.status.approved') as string;
          break;
        case RequestStatus.PENDING:
          translation = i18n.t('requests.detail.status.pending') as string;
          break;
        case RequestStatus.VALIDATED:
          translation = i18n.t('requests.detail.status.pending') as string;
          break;
        case RequestStatus.CANCELLED_BY_MANAGER:
          translation = i18n.t('requests.detail.status.rejected') as string;
          break;
        case RequestStatus.SAVED:
          translation = i18n.t('requests.detail.status.saved') as string;
          break;
        default:
          break;
      }
      return { translation };
    };

    return {
      headers,
      getStatusDetails,
    };
  },
});
