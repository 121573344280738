import { defineComponent, onMounted, ref, watch } from 'vue';
import { useStore } from '@/plugins/vuex';
import Filters, { FilterFields } from '@/sections/shared/explore/model/Filters';

export default defineComponent({
  props: {
    filterField: String,
    action: {
      type: Function,
    },
    tempFilters: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props) {
    const store = useStore();
    const value = ref<boolean>(false);

    onMounted(() => {
      if (props.filterField) {
        value.value = getComponentState();
      }
    });

    if (!props.tempFilters) {
      watch(
        () => store.getters['filterStore/filters'],
        (filters: Filters) => {
          value.value = getComponentState();
        },
        { deep: true }
      );
    }

    if (props.tempFilters) {
      watch(
        () => store.getters['filterStore/tempFilters'],
        (filters: Filters) => {
          value.value = getComponentState();
        },
        { deep: true }
      );
    }

    const getComponentState = (): boolean => {
      const payload = props.filterField as FilterFields;
      if (!props.tempFilters) {
        return store.getters['filterStore/getCheckboxState'](payload);
      }
      return store.getters['filterStore/getCheckboxTempState'](payload);
    };

    const handleChange = (val: boolean) => {
      props.action(props.filterField, val);
    };

    return {
      value,
      handleChange,
    };
  },
});
