import { Authority } from '@/shared/security/authority';
import ExplorePage from '@/pages/client/explore/ExplorePage.vue';
import CheckoutPage from '@/pages/client/checkout/CheckoutPage.vue';
import TeamRequestsDetailPage from '@/pages/client/team-requests-detail/TeamRequestsDetailPage.vue';
import TeamRequestsPage from '@/pages/client/team-requests/TeamRequestsPage.vue';
import CandidatesPage from '@/pages/client/candidates/CandidatesPage.vue';

export default [
  {
    path: '/explore',
    name: 'exploreClient',
    component: ExplorePage,
    meta: { authorities: [Authority.CLIENT] },
  },
  {
    path: '/checkout',
    name: 'checkoutClient',
    component: CheckoutPage,
    meta: { authorities: [Authority.CLIENT] },
  },
  {
    path: '/team-requests/:id',
    name: 'teamRequestByIdClient',
    component: TeamRequestsDetailPage,
    props: (route: { params: { id: any } }) => ({ teamRequestId: route.params.id } as { teamRequestId: string }),
    meta: { authorities: [Authority.CLIENT] },
  },
  {
    path: '/team-requests',
    name: 'requestsClient',
    component: TeamRequestsPage,
    meta: { authorities: [Authority.CLIENT] },
  },
  {
    path: '/teams/candidates',
    name: 'teamsCandidatesClient',
    component: CandidatesPage,
    meta: { authorities: [Authority.CLIENT] },
  },
];
