import { render, staticRenderFns } from "./StemdoerCard.vue?vue&type=template&id=5e8e615c&scoped=true"
import script from "./stemdoerCard.ts?vue&type=script&lang=ts&external"
export * from "./stemdoerCard.ts?vue&type=script&lang=ts&external"
import style0 from "./StemdoerCard.vue?vue&type=style&index=0&id=5e8e615c&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e8e615c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/r1/a/_stemdoplatform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5e8e615c')) {
      api.createRecord('5e8e615c', component.options)
    } else {
      api.reload('5e8e615c', component.options)
    }
    module.hot.accept("./StemdoerCard.vue?vue&type=template&id=5e8e615c&scoped=true", function () {
      api.rerender('5e8e615c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/sections/shared/explore/stemdoer-card/StemdoerCard.vue"
export default component.exports