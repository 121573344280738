import { computed, defineComponent } from 'vue';
import SAvatarStemdoer from '@/components/stemdo-components/s-avatar-stemdoer/SAvatarStemdoer.vue';
import { useI18N } from '@/plugins/i18n';

export default defineComponent({
  props: {
    candidates: {
      type: Array,
      required: true,
    },
  },
  components: {
    SAvatarStemdoer,
  },
  setup(props) {
    const i18n = useI18N();
    const headers = computed(() => [
      { text: i18n.t('candidate.table.name'), value: 'fullName', width: '25%' },
      { text: i18n.t('candidate.table.title'), value: 'position', width: '35%' },
      { text: i18n.t('candidate.table.hourlyCost'), value: 'rate', width: '16%' },
      { text: i18n.t('candidate.table.team'), value: 'team', width: '25%' },
    ]);

    const candidatesWithFullNameAndRate = computed(() => {
      return (props.candidates as { name: string; surname: string; stemdoerTeams: { rate: number }[] }[]).map(candidate => ({
        ...candidate,
        fullName: `${candidate.name} ${candidate.surname}`,
        rate: candidate.stemdoerTeams.length > 0 ? candidate.stemdoerTeams[0].rate : 'N/A',
      }));
    });

    return {
      headers,
      candidates: candidatesWithFullNameAndRate,
    };
  },
});
