var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "s-button-card" } },
    [
      _c(
        "v-btn",
        {
          class: {
            active: _vm.btnSelected == "all",
            off: _vm.btnSelected != "all",
            "btn-main": true,
            "ml-0": true,
          },
          attrs: { elevation: "0" },
          on: {
            click: function ($event) {
              return _vm.getActive("all")
            },
          },
        },
        [
          _c("div", { staticClass: "w-100 text-left", attrs: { id: "all" } }, [
            _c(
              "h2",
              {
                staticClass: "text-h2 mb-2",
                staticStyle: { "line-height": "1.2" },
              },
              [_vm._v(_vm._s(_vm.listTeamRequest.length))]
            ),
            _vm._v(" "),
            _c("p", { staticClass: "text-body-2-bold text-capitalize mb-0" }, [
              _vm._v(_vm._s(_vm.$t("requests.status." + "all"))),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.getStatusButton(_vm.statusCount), function (value, index) {
        return _c(
          "v-btn",
          {
            key: index,
            class: {
              active: _vm.btnSelected == value.name,
              "btn-main": true,
              off: _vm.btnSelected != value.name,
            },
            attrs: { stacked: "", elevation: "0" },
            on: {
              click: function ($event) {
                return _vm.getActive(value.name)
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "w-100 text-left", attrs: { id: value.name } },
              [
                _c(
                  "h2",
                  {
                    staticClass: "text-h2 mb-2",
                    staticStyle: { "line-height": "1.2" },
                  },
                  [_vm._v(_vm._s(value.count))]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "text-body-2-bold text-capitalize mb-0" },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          "requests.status." + value.name ==
                            "requests.status.pending"
                            ? _vm.$t("requests.status.active")
                            : _vm.$t("requests.status." + value.name)
                        ) +
                        "\n      "
                    ),
                  ]
                ),
              ]
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }