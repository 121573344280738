<template>
  <v-hover>
    <template v-slot:default="{ hover }">
      <v-list-item
        :class="['pr-0 pb-1 mb-0', item.disable === true ? 'cursor-default' : 'cursor-pointer']"
        @click="goToPage(item.path, $route.path)"
      >
        <div class="vl-wrapper mr-5">
          <div
            :class="[
              (isActiveLink(item.activePaths, $route.path) || hover) && item.disable === false
                ? isLast
                  ? 'vl-active-last'
                  : 'vl-active'
                : isLast
                ? 'vl-last'
                : 'vl',
            ]"
          >
            <div class="middle"></div>
          </div>
        </div>
        <v-list-item-content class="py-1">
          <v-list-item-title
            :class="[
              'py-1',
              { 'text-body-1-bold text--text text--lighten-1': isActiveLink(item.activePaths, $route.path) },
              { 'text-body-1-medium text--text text--lighten-1': item.disable === false && !isActiveLink(item.activePaths, $route.path) },
              { 'text-body-1 text--text text--lighten-1': item.disable === true },
            ]"
            >{{ $t(item.title) }}</v-list-item-title
          >
        </v-list-item-content>
        <v-list-item-icon v-if="item.disable">
          <v-img :width="24" :src="'/content/svgs/lock.svg'"></v-img>
        </v-list-item-icon>
        <BadgetTest :item="item" />
      </v-list-item>
    </template>
  </v-hover>
</template>

<script lang="ts">
import { useRouter } from '@/plugins/router';
import BadgetTest from './BadgetTest.vue';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    isLast: {
      type: Boolean,
      required: true,
    },
    isActiveLink: {
      type: Function,
      required: true,
    },
  },
  components: {
    BadgetTest,
  },
  setup() {
    const router = useRouter();

    const goToPage = (path: string, currentPath: string) => {
      if (!path || path === '') return;
      if (currentPath === path) return;
      router.push(path);
    };

    return { goToPage };
  },
};
</script>

<style lang="scss" scoped>
/* Style for vertical line before elements in the nav */
.vl-wrapper {
  display: flex;
  justify-content: center;
  width: 24px;

  .vl {
    position: absolute;
    top: 0px;

    background-color: var(--v-border-darken1);
    width: 2px;
    height: 44px;
  }

  .vl-last {
    position: absolute;
    top: 0px;

    background-color: var(--v-border-darken1);
    width: 2px;
    height: 26px;
  }

  .vl-active {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    /* Default color for the line */

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 50%;
      background-color: var(--v-border-darken1); /* Default color for top and bottom parts */
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 42%;
      background-color: var(--v-border-darken1); /* Default color for top and bottom parts */
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    .middle {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 15px; /* Adjust height as needed */
      background-color: var(--v-primary-base); /* Color for the middle part */
      width: 100%;
    }
  }

  .vl-active-last {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    /* Default color for the line */

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 50%;
      background-color: var(--v-border-darken1); /* Default color for top and bottom parts */
    }

    &::before {
      top: 0;
    }

    .middle {
      position: absolute;
      top: 46%;
      transform: translateY(-50%);
      height: 11px; /* Adjust height as needed */
      background-color: var(--v-primary-base); /* Color for the middle part */
      width: 100%;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
</style>
