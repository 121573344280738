import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue';
import TeamRequestService from '@/entities/team-request/team-request.service';
import { useStore } from '@/plugins/vuex';
import NoContent from '@/sections/client/team-requests/no-content/NoContent.vue';
import TeamRequestList from '@/sections/client/team-requests/team-request-list/TeamRequestList.vue';
import { StatusFilter } from './filter-card/filterCard';
import FilterCard from './filter-card/FilterCard.vue';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import sButton from '@/components/stemdo-components/s-button/SButton.vue';
import sModalHalfRight from '@/components/stemdo-components/s-modal-right/SModalRight.vue';
import TeamRequestDetail from '@/sections/client/team-requests/team-request-detail/TeamRequestDetail.vue';
import { ITeamRequest } from '@/shared/model/team-request.model';
import router from '@/router';
import TeamRequestListSkeleton from '../../shared/team-request-list-skeleton/TeamRequestListSkeleton.vue';

export default defineComponent({
  components: {
    NoContent,
    TeamRequestList,
    TeamRequestListSkeleton,
    FilterCard,
    sModalHalfRight,
    sButton,
    TeamRequestDetail,
  },
  setup() {
    const store = useStore();
    const teamRequestService: TeamRequestService = inject('teamRequestService');
    const teamRequestsDisplayed = ref<ITeamRequest[]>([]);
    const teamRequestsFiltered = ref<ITeamRequest[]>([]);
    const isLoading = ref(true);
    const isRequestDetailModalOpen = ref(false);
    const requestSelected = ref([]);
    const isCancelModalOpen = ref(false);
    const cancelModalTitleName = ref('');
    const requestToCancel = ref(null);
    const statusFilterSelected = ref<StatusFilter>(StatusFilter.All);
    const proposalLink = ref('');

    const displaySkeleton = computed(() => {
      return isLoading.value || (teamRequestsDisplayed.value.length === 0 && isLoading.value);
    });

    onMounted(() => {
      retrieveAllClientTeamRequestByClientId();
    });

    const closeCancelModal = () => {
      isCancelModalOpen.value = false;
    };

    const openCancelModal = (item: any) => {
      requestToCancel.value = item;
      cancelModalTitleName.value = item.name;
      isCancelModalOpen.value = true;
    };

    const cancelTeamRequest = () => {
      teamRequestService
        .changeStatus(requestToCancel.value.id, RequestStatus.CANCELLED_BY_CLIENT)
        .then(res => {
          retrieveAllClientTeamRequestByClientId();
        })
        .catch(err => {
          console.error(err);
        });
      isCancelModalOpen.value = false;
    };

    const openRequestDetailModal = request => {
      requestSelected.value = request;
      isRequestDetailModalOpen.value = true;
      checkStatusAndGenerateLink(request);
    };

    const closeRequestDetailModal = () => {
      isRequestDetailModalOpen.value = false;
      proposalLink.value = '';
    };

    const getStatusSelectedAndFilter = filterName => {
      statusFilterSelected.value = filterName;
      isLoading.value = true;
      const status = filterName;
      if (status === StatusFilter.All) {
        teamRequestsFiltered.value = teamRequestsDisplayed.value;
      }
      if (status === StatusFilter.Saved) {
        teamRequestsFiltered.value = teamRequestsDisplayed.value.filter((teamRequest: any) => teamRequest.status == RequestStatus.SAVED);
      }
      if (status === StatusFilter.Pending) {
        teamRequestsFiltered.value = teamRequestsDisplayed.value.filter(
          (teamRequest: any) => teamRequest.status == RequestStatus.PENDING || teamRequest.status == RequestStatus.VALIDATED
        );
      }
      if (status === StatusFilter.Approved) {
        teamRequestsFiltered.value = teamRequestsDisplayed.value.filter((teamRequest: any) => teamRequest.status == RequestStatus.ON_GOING);
      }
      if (status === StatusFilter.Rejected) {
        teamRequestsFiltered.value = teamRequestsDisplayed.value.filter(
          (teamRequest: any) => teamRequest.status == RequestStatus.CANCELLED_BY_MANAGER
        );
      }
      isLoading.value = false;
    };

    const refreshTeamRequestList = () => {
      retrieveAllClientTeamRequestByClientId();
    };

    const retrieveAllClientTeamRequestByClientId = () => {
      const clientId = store.getters.clientId;
      const getAllTeamRequestsByClientIdBody = {
        requestStatus: [
          RequestStatus.SAVED,
          RequestStatus.PENDING,
          RequestStatus.VALIDATED,
          RequestStatus.ON_GOING,
          RequestStatus.CANCELLED_BY_MANAGER,
        ],
      };
      isLoading.value = true;
      if (clientId != null) {
        teamRequestService
          .getAllTeamRequestsByClientId(clientId, getAllTeamRequestsByClientIdBody)
          .then(res => {
            teamRequestsDisplayed.value = res.data;
            teamRequestsFiltered.value = res.data;
            isLoading.value = false;
          })
          .catch(err => {
            console.error(err);
          });
      }
    };

    const checkStatusAndGenerateLink = requestSelected => {
      if (requestSelected.status === RequestStatus.SAVED) {
        const link = router.resolve({ name: 'teamRequestByIdClient', params: { id: requestSelected.id } }).href;
        proposalLink.value = window.location.origin + link;
        return proposalLink.value;
      }
      return null;
    };

    const copyToClipboard = () => {
      navigator.clipboard.writeText(proposalLink.value);
    };

    return {
      teamRequestsDisplayed,
      teamRequestsFiltered,
      cancelModalTitleName,
      isLoading,
      isRequestDetailModalOpen,
      requestSelected,
      isCancelModalOpen,
      statusFilterSelected,
      proposalLink,
      displaySkeleton,
      getStatusSelectedAndFilter,
      openRequestDetailModal,
      closeRequestDetailModal,
      cancelTeamRequest,
      openCancelModal,
      closeCancelModal,
      refreshTeamRequestList,
      copyToClipboard,
    };
  },
});
