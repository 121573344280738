var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { attrs: { id: "teamStemdoers" } },
    [
      this.loaded
        ? _c("div", { staticClass: "checkout" }, [
            _c("div", { staticClass: "toolBoxTop" }, [
              _c("div", [_c("SButtonBack")], 1),
              _vm._v(" "),
              _c("div", [
                _c("span", [
                  _vm._v(_vm._s(_vm.$t("label.teams.selected.myTeam")) + " "),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex justify-content-end align-items-end" },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "btnPDF",
                      staticStyle: {
                        "background-color": "rgba(255, 255, 255, 0) !important",
                      },
                      attrs: {
                        loading: _vm.loading,
                        disabled: _vm.loading,
                        fab: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.loading = true
                          _vm.startLoading()
                        },
                      },
                    },
                    [
                      _c("v-img", {
                        staticClass: "iconPDF",
                        attrs: { src: "/content/svgs/pdf-button.svg" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "ml-1" }, [_vm._v("PDF")]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "checkout-container" }, [
              _c(
                "div",
                { staticClass: "checkout-view" },
                [
                  _vm.errorPopUp
                    ? _c("SPopUp", {
                        staticClass: "checkoutError",
                        attrs: {
                          sText: _vm.$t("label.teams.selected.errorPopUp"),
                          isValid: !_vm.errorPopUp,
                        },
                        on: { close: _vm.closePopUp },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "checkout-team" },
                    _vm._l(_vm.arrayStemdoers, function (value) {
                      return _c(
                        "div",
                        { key: value.id, staticClass: "cardContainer" },
                        [
                          _c(
                            "div",
                            { staticClass: "containerIMG" },
                            [
                              _c("SAvatarStemdoer", {
                                staticClass: "imgStemdoer",
                                attrs: {
                                  stemdoId: value.stemdoId,
                                  size: "70px",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "card-details w-100" }, [
                            _c("div", { staticClass: "card-text w-100" }, [
                              _c("div", { staticClass: "card-text-top" }, [
                                value.surname
                                  ? _c(
                                      "div",
                                      { staticClass: "card-personal" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "card-name" },
                                          [
                                            _vm._v(
                                              _vm._s(value.name) +
                                                " " +
                                                _vm._s(
                                                  value.surname.charAt(0) + "."
                                                )
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          { staticClass: "card-position" },
                                          [_vm._v(" " + _vm._s(value.position))]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "card-delete pt-2 ml-auto" },
                                  [
                                    _c("span", {
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t("label.teams.selected.details")
                                        ),
                                      },
                                      on: {
                                        click: () => {
                                          _vm.stemdoerSelected = value
                                          _vm.dialog = !_vm.dialog
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "pl-2 pr-2",
                                        staticStyle: { cursor: "default" },
                                      },
                                      [_vm._v("·")]
                                    ),
                                    _vm._v(" "),
                                    _vm.teamRequestStatus === null ||
                                    _vm.teamRequestStatus ===
                                      _vm.RequestStatus.SAVED
                                      ? _c("span", {
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(
                                                "label.teams.selected.discard"
                                              )
                                            ),
                                          },
                                          on: {
                                            click: (e) =>
                                              _vm.deleteStemdoer(value.id),
                                          },
                                        })
                                      : _vm._e(),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "skillsContainer" },
                                _vm._l(
                                  _vm.stemdoerShowBadge[value.stemdoId],
                                  function (badgeSkills, index) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex flex-nowrap flex-shrink-0",
                                      },
                                      [
                                        _vm.$vuetify.breakpoint.smAndDown &&
                                        index < 1
                                          ? _c(
                                              "div",
                                              { staticClass: "skillsCards" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      badgeSkills.technology
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.$vuetify.breakpoint.mdOnly &&
                                        index < 2
                                          ? _c(
                                              "div",
                                              { staticClass: "skillsCards" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      badgeSkills.technology
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.$vuetify.breakpoint.lgOnly &&
                                        index < 3
                                          ? _c(
                                              "div",
                                              { staticClass: "skillsCards" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      badgeSkills.technology
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.$vuetify.breakpoint.xlOnly &&
                                        index < 5
                                          ? _c(
                                              "div",
                                              { staticClass: "skillsCards" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      badgeSkills.technology
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _vm.isClient && _vm.totalHourlyCost > 0
                    ? _c("div", { staticClass: "checkout-info" }, [
                        _c("div", { staticClass: "totalCost" }, [
                          _c("span", {
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("label.teams.selected.teamCostHour")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c("h2", [
                            _vm._v(
                              "≈" +
                                _vm._s(
                                  _vm.$n(
                                    _vm.totalHourlyCost /
                                      _vm.arrayStemdoers.length,
                                    "decimal"
                                  )
                                ) +
                                "€/h"
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "checkout-totalCost" }, [
                          _c(
                            "div",
                            {
                              staticClass: "totalCost-mini",
                              staticStyle: { "margin-right": "6px" },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("label.teams.selected.teamCostDay")
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("h4", [
                                _vm._v(
                                  "≈" +
                                    _vm._s(
                                      _vm.$n(_vm.totalDailyCost, "decimal")
                                    ) +
                                    "€"
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "totalCost-mini",
                              staticStyle: { "margin-left": "6px" },
                            },
                            [
                              _c("span", {
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.$t("label.teams.selected.teamCostMonth")
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("h4", [
                                _vm._v(
                                  "≈" +
                                    _vm._s(
                                      _vm.$n(_vm.totalDailyCost * 20, "decimal")
                                    ) +
                                    "€"
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _vm.teamRequestStatus === null ||
                        _vm.teamRequestStatus === _vm.RequestStatus.SAVED
                          ? _c(
                              "div",
                              { staticClass: "checkout-buttons" },
                              [
                                _c("SButton", {
                                  attrs: {
                                    btnStyle: "primary",
                                    sText: "entity.action.block",
                                    action: () => {
                                      _vm.checkWrongDate()
                                      _vm.formatDateLanguage()
                                      _vm.executeBlock()
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("SButton", {
                                  staticStyle: { "margin-top": "16px" },
                                  attrs: {
                                    btnStyle: "secondary",
                                    sText: "label.teams.selected.saveProposal",
                                    action: () => {
                                      _vm.formatDateLanguage()
                                      _vm.prepareProposal()
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm.teamRequestStatus ===
                            _vm.RequestStatus.VALIDATED
                          ? _c(
                              "div",
                              { staticClass: "checkout-buttons" },
                              [
                                _c("SButton", {
                                  attrs: {
                                    btnStyle: "primary",
                                    sText: "requests.info-box.pending.accept",
                                    action: _vm.approveTeamRequest,
                                  },
                                }),
                                _vm._v(" "),
                                _c("SButton", {
                                  staticStyle: { "margin-top": "16px" },
                                  attrs: {
                                    btnStyle: "secondary",
                                    sText: "requests.info-box.pending.send-msg",
                                    action: () => {},
                                    disabled: true,
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "checkout-chart" }, [
                          _c("span", { staticClass: "title-span" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("label.teams.selected.averageSkill")
                              )
                            ),
                          ]),
                          _vm._v(" "),
                          this.loaded
                            ? _c(
                                "div",
                                {
                                  ref: "chartSelected",
                                  staticClass: "chart-container",
                                  staticStyle: {
                                    position: "relative",
                                    width: "100%",
                                    height: "100%",
                                  },
                                },
                                [
                                  _c("Radar", {
                                    key: _vm.chartKey,
                                    attrs: {
                                      id: "charRadar",
                                      data: _vm.data,
                                      options: _vm.options,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "checkout-footprint" }, [
                          _c("span", { staticClass: "title-span" }, [
                            _vm._v(
                              _vm._s(_vm.$t("label.teams.selected.footprintLb"))
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "svg",
                              {
                                staticClass: "circle-container",
                                attrs: {
                                  width: "134",
                                  height: "134",
                                  viewBox: "0 0 134 134",
                                  fill: "none",
                                },
                              },
                              [
                                _c(
                                  "g",
                                  { attrs: { transform: "scale(1,-1)" } },
                                  [
                                    _c("circle", {
                                      attrs: {
                                        cx: "67",
                                        cy: "-67",
                                        r: "56",
                                        fill: "#F9F9F9",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("circle", {
                                      attrs: {
                                        cx: "67",
                                        cy: "-67",
                                        r: "66",
                                        stroke: "#DBDBE0",
                                        "stroke-width": "2",
                                        "stroke-linejoin": "round",
                                        "stroke-dasharray": "2 2",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("circle", {
                                      staticClass: "progress-ring__circle",
                                      style: _vm.footprintCircle(this.footp),
                                      attrs: {
                                        stroke: "url(#paint0_linear_447_20326)",
                                        "stroke-width": "3",
                                        "stroke-linecap": "round",
                                        transform: "rotate(-90, 67, -67)",
                                        fill: "transparent",
                                        r: "66",
                                        cx: "67",
                                        cy: "-67",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          id: "paint0_linear_447_20326",
                                          x1: "10.6688",
                                          y1: "-120.6",
                                          x2: "132.842",
                                          y2: "-112.519",
                                          gradientUnits: "userSpaceOnUse",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: { "stop-color": "#4237FF" },
                                        }),
                                        _vm._v(" "),
                                        _c("stop", {
                                          attrs: {
                                            offset: "1",
                                            "stop-color": "#9747FF",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "foreignObject",
                                      {
                                        attrs: {
                                          x: "0",
                                          y: "-134",
                                          width: "134",
                                          height: "134",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "footprint-info" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "footprint-card" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "footprint-location",
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        this.locationTeam
                                                      ) + " "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "footprint-value",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          this.footp ==
                                                            undefined
                                                            ? 6
                                                            : this.footp
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "footprint-tco",
                                                  },
                                                  [
                                                    _vm._v(" tCO"),
                                                    _c("sub", [_vm._v("2")]),
                                                    _vm._v(
                                                      "eq/" +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "label.teams.selected.footprintYear"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "svg",
                              {
                                staticClass: "circle-container",
                                attrs: {
                                  width: "134",
                                  height: "134",
                                  viewBox: "0 0 134 134",
                                  fill: "none",
                                },
                              },
                              [
                                _c(
                                  "g",
                                  { attrs: { transform: "scale(1,-1)" } },
                                  [
                                    _c("circle", {
                                      attrs: {
                                        cx: "67",
                                        cy: "-67",
                                        r: "56",
                                        fill: "#F9F9F9",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("circle", {
                                      attrs: {
                                        cx: "67",
                                        cy: "-67",
                                        r: "66",
                                        stroke: "#DBDBE0",
                                        "stroke-width": "2",
                                        "stroke-linejoin": "round",
                                        "stroke-dasharray": "2 2",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("circle", {
                                      staticClass: "progress-ring__circle",
                                      style: _vm.footprintCircle(
                                        this.arrayStemdoers.length * 12
                                      ),
                                      attrs: {
                                        stroke: "url(#paint0_linear_447_20326)",
                                        "stroke-width": "3",
                                        "stroke-linecap": "round",
                                        transform: "rotate(-90, 67, -67)",
                                        fill: "transparent",
                                        r: "66",
                                        cx: "67",
                                        cy: "-67",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "linearGradient",
                                      {
                                        attrs: {
                                          id: "paint0_linear_447_20326",
                                          x1: "10.6688",
                                          y1: "-120.6",
                                          x2: "132.842",
                                          y2: "-112.519",
                                          gradientUnits: "userSpaceOnUse",
                                        },
                                      },
                                      [
                                        _c("stop", {
                                          attrs: { "stop-color": "#4237FF" },
                                        }),
                                        _vm._v(" "),
                                        _c("stop", {
                                          attrs: {
                                            offset: "1",
                                            "stop-color": "#9747FF",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "foreignObject",
                                      {
                                        attrs: {
                                          x: "0",
                                          y: "-134",
                                          width: "134",
                                          height: "134",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "footprint-info" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "footprint-card" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "footprint-location",
                                                  },
                                                  [_vm._v("Madrid")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "footprint-value",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " ~ " +
                                                        _vm._s(
                                                          this.arrayStemdoers
                                                            .length * 12
                                                        )
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "footprint-tco",
                                                  },
                                                  [
                                                    _vm._v(" tCO"),
                                                    _c("sub", [_vm._v("2")]),
                                                    _vm._v(
                                                      "eq/" +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "label.teams.selected.footprintYear"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "modalVertical",
          attrs: {
            size: "lg",
            id: "user-details",
            "hide-footer": "",
            lazy: "",
          },
          on: {
            show: function ($event) {
              return _vm.onModalShow()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-title",
              fn: function () {
                return [
                  _c(
                    "span",
                    { attrs: { "data-cy": "userDetails", id: "user-detail" } },
                    [
                      _c("div", { staticClass: "centerPDF" }, [
                        _c("img", {
                          staticClass: "minilogo",
                          attrs: { src: "/content/images/emini.png", alt: "" },
                        }),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.nameSelected) +
                            "\n          "
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btnPDF",
                            on: {
                              click: function ($event) {
                                return _vm.generatePDF()
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: "fa-solid fa-file-pdf" },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("show-detail", {
            attrs: { people: this.people, peopleImg: this.peopleImg },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "blockEntity",
          staticClass: "checkout-modal",
          attrs: { id: "blockEntity" },
          on: {
            show: function ($event) {
              return _vm.onModalShow()
            },
          },
        },
        [
          _c("span", {
            attrs: { slot: "modal-title" },
            domProps: {
              textContent: _vm._s(_vm.$t("label.teams.selected.confirmTitle")),
            },
            slot: "modal-title",
          }),
          _vm._v(" "),
          _c("div", { staticStyle: { padding: "16px" } }, [
            _c("div", [
              _c("div", [
                _c("span", {
                  staticClass: "modal-span",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("label.teams.selected.confirmName")
                    ),
                  },
                }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.teamRequestName,
                      expression: "teamRequestName",
                    },
                  ],
                  ref: "teamRequestName",
                  staticClass: "input-form-stemdo",
                  attrs: {
                    id: "teamRequestName",
                    type: "text",
                    placeholder: _vm
                      .$t("label.teams.selected.namePlaceholder")
                      .toString(),
                  },
                  domProps: { value: _vm.teamRequestName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.teamRequestName = $event.target.value
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _vm.isClient
              ? _c("div", { staticClass: "date-container" }, [
                  _c(
                    "div",
                    { staticClass: "dateInputs" },
                    [
                      _c("span", { staticClass: "modal-span" }, [
                        _vm._v(_vm._s(_vm.$t("label.teams.selected.initDate"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-menu",
                        {
                          ref: "menuStartDate",
                          attrs: {
                            "close-on-content-click": false,
                            "return-value": _vm.initDate,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "auto",
                          },
                          on: {
                            "update:returnValue": function ($event) {
                              _vm.initDate = $event
                            },
                            "update:return-value": function ($event) {
                              _vm.initDate = $event
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-text-field",
                                      _vm._g(
                                        {
                                          staticClass: "no-hover",
                                          attrs: {
                                            type: "text",
                                            readonly: "",
                                            outlined: "",
                                            "prepend-inner-icon":
                                              "mdi-calendar",
                                            placeholder: _vm.formattedStartDate
                                              ? ""
                                              : _vm.$t(
                                                  "label.teams.selected.initDateTip"
                                                ),
                                          },
                                          model: {
                                            value: _vm.formattedStartDate,
                                            callback: function ($$v) {
                                              _vm.formattedStartDate = $$v
                                            },
                                            expression: "formattedStartDate",
                                          },
                                        },
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          "\n                >\n              "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1060856615
                          ),
                          model: {
                            value: _vm.menuStartDate,
                            callback: function ($$v) {
                              _vm.menuStartDate = $$v
                            },
                            expression: "menuStartDate",
                          },
                        },
                        [
                          _vm._v(" "),
                          _c("v-date-picker", {
                            attrs: {
                              "no-title": "",
                              scrollable: "",
                              min: _vm.initDate,
                              max: _vm.endDate,
                            },
                            on: {
                              input: (initDate) => {
                                _vm.checkWrongDate()
                                _vm.menuStartDate = false
                              },
                            },
                            model: {
                              value: _vm.startDate,
                              callback: function ($$v) {
                                _vm.startDate = $$v
                              },
                              expression: "startDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.showEndDate
                    ? _c("div", { staticClass: "end-date-option" }, [
                        _c("span", {
                          attrs: { text: "" },
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("label.teams.selected.enterEndDate")
                            ),
                          },
                          on: { click: _vm.toggleEndDateInput },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showEndDate
                    ? _c(
                        "div",
                        { staticClass: "dateInputs" },
                        [
                          _c("span", {
                            staticClass: "modal-span",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("label.teams.selected.endDate")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              ref: "menuEndDate",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.endDate,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "auto",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  _vm.endDate = $event
                                },
                                "update:return-value": function ($event) {
                                  _vm.endDate = $event
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            {
                                              staticClass: "no-hover",
                                              attrs: {
                                                type: "text",
                                                readonly: "",
                                                outlined: "",
                                                "prepend-inner-icon":
                                                  "mdi-calendar",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "append",
                                                    fn: function () {
                                                      return [
                                                        _c("div", {
                                                          staticClass:
                                                            "clear-end-date-btn",
                                                          attrs: { text: "" },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "label.teams.selected.delete"
                                                              )
                                                            ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.clearFinishDate.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.formattedEndDate,
                                                callback: function ($$v) {
                                                  _vm.formattedEndDate = $$v
                                                },
                                                expression: "formattedEndDate",
                                              },
                                            },
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2144904580
                              ),
                              model: {
                                value: _vm.menuEndDate,
                                callback: function ($$v) {
                                  _vm.menuEndDate = $$v
                                },
                                expression: "menuEndDate",
                              },
                            },
                            [
                              _vm._v(" "),
                              _c("v-date-picker", {
                                attrs: {
                                  "no-title": "",
                                  scrollable: "",
                                  min: _vm.initDate,
                                  max: _vm.maxEndDate,
                                },
                                on: { input: _vm.checkWrongDate },
                                model: {
                                  value: _vm.finishDate,
                                  callback: function ($$v) {
                                    _vm.finishDate = $$v
                                  },
                                  expression: "finishDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "jobDescription" }, [
              _c("span", {
                staticClass: "modal-span",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("label.teams.selected.confirmObservations")
                  ),
                },
              }),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.teamRequestDescription,
                    expression: "teamRequestDescription",
                  },
                ],
                ref: "jobDescription",
                staticClass: "input-form-stemdo",
                staticStyle: {
                  "min-height": "135px",
                  "max-height": "135px",
                  "flex-shrink": "0",
                  resize: "none",
                },
                attrs: {
                  id: "jobDescription",
                  "data-cy": "jobDescription",
                  placeholder: _vm
                    .$t("label.teams.selected.jobDescription")
                    .toString(),
                },
                domProps: { value: _vm.teamRequestDescription },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.teamRequestDescription = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            !_vm.isAdminOrGestor && _vm.isClient
              ? _c(
                  "div",
                  { staticStyle: { "margin-top": "32px" } },
                  [
                    _c("SButton", {
                      attrs: {
                        btnStyle: "primary",
                        disabled:
                          this.isButtonDisabled && this.proposalId == "",
                        sText: "label.teams.selected.confirmBtn",
                        action: () => {
                          _vm.blockTeam()
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "finishBlock",
          staticStyle: {
            width: "100%",
            height: "100%",
            background: "white",
            "box-shadow": "0px 2px 8px #dbdbe0",
            "border-radius": "4px",
            padding: "20px",
            "text-align": "center",
          },
          attrs: { id: "finishBlock", "no-close-on-backdrop": "" },
          on: {
            hide: function ($event) {
              return _vm.closeDialog()
            },
            show: function ($event) {
              return _vm.onModalShow()
            },
          },
        },
        [
          _c("span", {
            attrs: { slot: "modal-title" },
            domProps: { textContent: _vm._s(_vm.modalTitle) },
            slot: "modal-title",
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { padding: "16px" } },
            [
              _c("span", {
                staticClass: "modal-finish",
                staticStyle: { display: "block", "margin-bottom": "22px" },
                domProps: { textContent: _vm._s(_vm.modalFinish) },
              }),
              _vm._v(" "),
              _c("SButton", {
                attrs: {
                  btnStyle: "primary",
                  sText: "label.teams.selected.understood",
                  action: () => {
                    _vm.closeDialog()
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "prepareProposal",
          attrs: { id: "prepareProposal" },
          on: {
            show: function ($event) {
              return _vm.onModalShow()
            },
          },
        },
        [
          _c("span", {
            attrs: { slot: "modal-title" },
            domProps: {
              textContent: _vm._s(
                _vm.$t("label.teams.selected.saveTeamProposal")
              ),
            },
            slot: "modal-title",
          }),
          _vm._v(" "),
          _c("div", { staticStyle: { padding: "16px" } }, [
            _vm.isAdminOrGestor && !_vm.isClient
              ? _c("div", { staticClass: "form-group" }, [
                  _c("label", {
                    staticClass: "custom-label",
                    attrs: { for: "team-request-client" },
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("stemdoPlatformApp.teamRequest.client")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedClientId,
                          expression: "selectedClientId",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        id: "team-request-client",
                        "data-cy": "client",
                        name: "client",
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedClientId = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function ($event) {
                            return _vm.checkWrongDate()
                          },
                        ],
                      },
                    },
                    [
                      _c("option", {
                        staticStyle: { color: "silver" },
                        attrs: { selected: "" },
                        domProps: {
                          value: null,
                          textContent: _vm._s(
                            _vm.$t("label.teams.selected.selectClient")
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "option",
                        {
                          staticStyle: { color: "black" },
                          domProps: { value: _vm.client.id },
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.client.name) +
                              " " +
                              _vm._s(_vm.client.surname)
                          ),
                        ]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isClient
              ? _c("div", [
                  _c("span", {
                    staticClass: "modal-span",
                    domProps: {
                      textContent: _vm._s(
                        _vm.$t("label.teams.selected.confirmName")
                      ),
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.teamRequestName,
                        expression: "teamRequestName",
                      },
                    ],
                    ref: "proposalName",
                    staticClass: "input-form-stemdo",
                    attrs: {
                      id: "proposalName",
                      type: "text",
                      placeholder: _vm
                        .$t("label.teams.selected.namePlaceholder")
                        .toString(),
                    },
                    domProps: { value: _vm.teamRequestName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.teamRequestName = $event.target.value
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isClient
              ? _c("div", { staticClass: "date-container" }, [
                  _c(
                    "div",
                    { staticClass: "dateInputs" },
                    [
                      _c("span", { staticClass: "modal-span" }, [
                        _vm._v(_vm._s(_vm.$t("label.teams.selected.initDate"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-menu",
                        {
                          ref: "menuStartDate",
                          attrs: {
                            "close-on-content-click": false,
                            "return-value": _vm.initDate,
                            transition: "scale-transition",
                            "offset-y": "",
                            "min-width": "auto",
                          },
                          on: {
                            "update:returnValue": function ($event) {
                              _vm.initDate = $event
                            },
                            "update:return-value": function ($event) {
                              _vm.initDate = $event
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on }) {
                                  return [
                                    _c(
                                      "v-text-field",
                                      _vm._g(
                                        {
                                          staticClass: "no-hover",
                                          attrs: {
                                            type: "text",
                                            readonly: "",
                                            outlined: "",
                                            "prepend-inner-icon":
                                              "mdi-calendar",
                                            placeholder: _vm.formattedStartDate
                                              ? ""
                                              : _vm.$t(
                                                  "label.teams.selected.initDateTip"
                                                ),
                                          },
                                          model: {
                                            value: _vm.formattedStartDate,
                                            callback: function ($$v) {
                                              _vm.formattedStartDate = $$v
                                            },
                                            expression: "formattedStartDate",
                                          },
                                        },
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2835439707
                          ),
                          model: {
                            value: _vm.menuStartDate,
                            callback: function ($$v) {
                              _vm.menuStartDate = $$v
                            },
                            expression: "menuStartDate",
                          },
                        },
                        [
                          _vm._v(" "),
                          _c("v-date-picker", {
                            attrs: {
                              "no-title": "",
                              scrollable: "",
                              min: _vm.initDate,
                              max: _vm.endDate,
                            },
                            on: {
                              input: (initDate) => {
                                _vm.checkWrongDate()
                                _vm.menuStartDate = false
                              },
                            },
                            model: {
                              value: _vm.startDate,
                              callback: function ($$v) {
                                _vm.startDate = $$v
                              },
                              expression: "startDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  !_vm.showEndDate
                    ? _c("div", { staticClass: "end-date-option" }, [
                        _c("span", {
                          attrs: { text: "" },
                          domProps: {
                            textContent: _vm._s(
                              _vm.$t("label.teams.selected.enterEndDate")
                            ),
                          },
                          on: { click: _vm.toggleEndDateInput },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showEndDate
                    ? _c(
                        "div",
                        { staticClass: "dateInputs" },
                        [
                          _c("span", {
                            staticClass: "modal-span",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("label.teams.selected.endDate")
                              ),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "v-menu",
                            {
                              ref: "menuEndDate",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.endDate,
                                transition: "scale-transition",
                                "offset-y": "",
                                "min-width": "auto",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  _vm.endDate = $event
                                },
                                "update:return-value": function ($event) {
                                  _vm.endDate = $event
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on }) {
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            {
                                              staticClass: "no-hover",
                                              attrs: {
                                                type: "text",
                                                readonly: "",
                                                outlined: "",
                                                "prepend-inner-icon":
                                                  "mdi-calendar",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "append",
                                                    fn: function () {
                                                      return [
                                                        _c("div", {
                                                          staticClass:
                                                            "clear-end-date-btn",
                                                          attrs: { text: "" },
                                                          domProps: {
                                                            textContent: _vm._s(
                                                              _vm.$t(
                                                                "label.teams.selected.delete"
                                                              )
                                                            ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.clearFinishDate.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: _vm.formattedEndDate,
                                                callback: function ($$v) {
                                                  _vm.formattedEndDate = $$v
                                                },
                                                expression: "formattedEndDate",
                                              },
                                            },
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                2144904580
                              ),
                              model: {
                                value: _vm.menuEndDate,
                                callback: function ($$v) {
                                  _vm.menuEndDate = $$v
                                },
                                expression: "menuEndDate",
                              },
                            },
                            [
                              _vm._v(" "),
                              _c("v-date-picker", {
                                attrs: {
                                  "no-title": "",
                                  scrollable: "",
                                  min: _vm.initDate,
                                  max: _vm.maxEndDate,
                                },
                                on: { input: _vm.checkWrongDate },
                                model: {
                                  value: _vm.finishDate,
                                  callback: function ($$v) {
                                    _vm.finishDate = $$v
                                  },
                                  expression: "finishDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "jobDescription" }, [
              _c("span", {
                staticClass: "modal-span",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("label.teams.selected.confirmObservations")
                  ),
                },
              }),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.teamRequestDescription,
                    expression: "teamRequestDescription",
                  },
                ],
                ref: "proposalJobDescription",
                staticClass: "input-form-stemdo",
                staticStyle: {
                  "min-height": "135px",
                  "max-height": "135px",
                  "flex-shrink": "0",
                  resize: "none",
                },
                attrs: {
                  id: "proposalJobDescription",
                  "data-cy": "jobDescription",
                  placeholder: _vm
                    .$t("label.teams.selected.jobDescription")
                    .toString(),
                },
                domProps: { value: _vm.teamRequestDescription },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.teamRequestDescription = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _vm.isAdminOrGestor || _vm.isClient
              ? _c(
                  "div",
                  { staticStyle: { "margin-top": "12px" } },
                  [
                    _c("SButton", {
                      staticStyle: { "margin-top": "12px" },
                      attrs: {
                        disabled: this.isButtonDisabled,
                        btnStyle: "primary",
                        sText: "label.teams.selected.btnProposal",
                        action: () => {
                          _vm.blockProposal()
                          _vm.checkWrongDate()
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          class: { "bg-white": true, "modal-open": _vm.dialog },
          attrs: { "max-width": "70%" },
          on: { input: () => _vm.resetOverflow() },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _vm.dialog
            ? _c("StemdoerDetail", {
                attrs: {
                  stemdoer: _vm.stemdoerSelected,
                  closeDialog: () => {
                    _vm.closeDetailDialog()
                  },
                  rates: _vm.rate,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }