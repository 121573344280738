import { render, staticRenderFns } from "./MainDrawerItem.vue?vue&type=template&id=38327581&scoped=true"
import script from "./mainDrawerItem.ts?vue&type=script&lang=ts&external"
export * from "./mainDrawerItem.ts?vue&type=script&lang=ts&external"
import style0 from "./MainDrawerItem.vue?vue&type=style&index=0&id=38327581&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38327581",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/r1/a/_stemdoplatform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('38327581')) {
      api.createRecord('38327581', component.options)
    } else {
      api.reload('38327581', component.options)
    }
    module.hot.accept("./MainDrawerItem.vue?vue&type=template&id=38327581&scoped=true", function () {
      api.rerender('38327581', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/components/main-drawer/main-drawer-item/MainDrawerItem.vue"
export default component.exports