var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "bg-white", attrs: { id: "stemdoerDetail" } },
    [
      _c(
        "div",
        { staticClass: "btn-exit", staticStyle: {} },
        [
          _c(
            "v-btn",
            {
              staticStyle: {
                "background-color": "#f9f9f9 !important",
                "z-index": "3",
              },
              attrs: {
                fab: "",
                dark: "",
                "x-small": "",
                color: "#DBDBE0",
                elevation: "0",
              },
              on: {
                click: function ($event) {
                  return _vm.closeDialog()
                },
              },
            },
            [
              _c("img", {
                staticStyle: { opacity: "0.8", transform: "scale(1.3)" },
                attrs: { src: "/content/svgs/close-tag.svg", width: "100%" },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "ma-0 pa-0" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-row",
                { staticClass: "mt-4" },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-center mr-6 mr-15 ml-1",
                      staticStyle: { "flex-direction": "column" },
                      attrs: { cols: "1" },
                    },
                    [
                      _c("SAvatarStemdoer", {
                        staticClass: "my-1 avatar",
                        staticStyle: { "z-index": "1" },
                        attrs: {
                          stemdoId: _vm.stemdoerMutable.stemdoId,
                          size: "120px",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { attrs: { cols: "8" } }, [
                    _c("div", { staticClass: "name-and-position" }, [
                      _c("div", [
                        _c(
                          "p",
                          {
                            staticClass: "text-body-1-bold",
                            staticStyle: { "margin-bottom": "-0.8em" },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.stemdoerMutable.name) +
                                " " +
                                _vm._s(
                                  _vm.stemdoerMutable.surname.split("")[0]
                                ) +
                                ".\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass: "text-h2",
                            staticStyle: {
                              color: "#222226",
                              height: "fit-content",
                              "margin-bottom": "0 !important",
                            },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.stemdoerMutable.position) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "infobadge" }, [
                      _vm.stemdoerMutable.english != "A1" &&
                      _vm.stemdoerMutable.english != "A2" &&
                      _vm.stemdoerMutable.english != ""
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-right": "4em",
                                "margin-top": "-0.25em",
                              },
                            },
                            [
                              _c(
                                "v-badge",
                                { attrs: { color: "transparent" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "background-color": "white",
                                      },
                                      attrs: { slot: "badge" },
                                      slot: "badge",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            border: "1px solid #dbdbe0",
                                            "border-radius": "50%",
                                            "background-color": "white",
                                            padding: "0.63em",
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              "border-radius": "50%",
                                            },
                                            attrs: {
                                              src: "/content/svgs/english-uk-lang.svg",
                                              width: "22em",
                                              height: "22em",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.stemdoerMutable.mbti
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                width: "fit-content",
                                height: "10%",
                                "padding-left": "10px",
                                "padding-right": "10px",
                                "padding-top": "9px",
                                "padding-bottom": "7px",
                                background: "white",
                                "border-radius": "4px",
                                border: "1px #dbdbe0 solid",
                                "justify-content": "center",
                                "align-items": "center",
                                gap: "10px",
                                display: "inline-flex",
                                "z-index": "2",
                              },
                            },
                            [
                              _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#606061",
                                      "font-size": "14px",
                                      "font-family": "Roboto Mono",
                                      "font-weight": "400",
                                      "word-wrap": "break-word",
                                    },
                                  },
                                  [_vm._v("MBTI:")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "#606061",
                                      "font-size": "14px",
                                      "font-family": "Roboto Mono",
                                      "font-weight": "500",
                                      "word-wrap": "break-word",
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticStyle: {
                                          color: "inherit",
                                          "text-decoration": "none",
                                        },
                                        attrs: {
                                          target: "_blank",
                                          href:
                                            "https://www.16personalities.com/" +
                                            _vm.stemdoerMutable.mbti.toLowerCase() +
                                            "-personality",
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(_vm.stemdoerMutable.mbti)
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        right: "0",
                        top: "3.5em",
                      },
                    },
                    [
                      _c("v-row", { staticClass: "d-flex justify-end" }, [
                        _c(
                          "div",
                          { staticClass: "toolBoxTop mb-4" },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "btnPDF",
                                staticStyle: {
                                  "margin-top": "-0.2em",
                                  "background-color":
                                    "rgba(255, 255, 255, 0) !important",
                                },
                                attrs: {
                                  loading: _vm.loading,
                                  disabled: _vm.loading,
                                  fab: "",
                                },
                                on: {
                                  click: () => {
                                    _vm.loading = true
                                    _vm.startLoading()
                                  },
                                },
                              },
                              [
                                _c("v-img", {
                                  staticClass: "iconPDF",
                                  attrs: {
                                    src: "/content/svgs/pdf-button.svg",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                staticClass: "pdf",
                                attrs: {
                                  loading: _vm.loading,
                                  disabled: _vm.loading,
                                },
                                on: {
                                  click: function ($event) {
                                    _vm.loading = true
                                    _vm.startLoading()
                                  },
                                },
                              },
                              [_vm._v("PDF")]
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      !_vm.StemdoerisInCart &&
                      _vm.stemdoerMutable.status !==
                        _vm.StemdoerStatus.ACTIVE &&
                      _vm.$route.name !== "teamRequestByIdClient"
                        ? _c(
                            "v-row",
                            { staticClass: "d-flex justify-end" },
                            [
                              _c("SButton", {
                                staticStyle: {
                                  "max-width": "175px !important",
                                  width: "175px !important",
                                },
                                attrs: {
                                  btnStyle: "primary",
                                  sText: _vm.$t(
                                    "explore.stemdoer-detail.addBtn"
                                  ),
                                  action: _vm.handleAddToCartBtn,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass: "mt-6 text-body-1 mr-4",
                          staticStyle: {
                            "margin-left": "-0.7em",
                            color: "#606061",
                            "text-align": "center",
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("explore.stemdoer-detail.rate")) +
                              ":\n            "
                          ),
                          _c(
                            "span",
                            {
                              staticClass: "text-body-1-bold mr-5",
                              staticStyle: {
                                color: "#4237ff",
                                "font-weight": "500",
                                "text-align": "start",
                                width: "100% !important",
                              },
                            },
                            [
                              _vm._v(
                                "≈" +
                                  _vm._s(
                                    _vm.getRate(_vm.stemdoerMutable.seniority)
                                  ) +
                                  "€/h"
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "firstBox" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-row", { staticClass: "mx-3 mt-3" }, [
                        _c("h5", [
                          _vm._v(
                            _vm._s(_vm.$t("explore.stemdoer-detail.skills"))
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mx-3 my-3" },
                        _vm._l(
                          _vm.stemdoerMutable.badgesSkills,
                          function (badge, index) {
                            return _vm.stemdoerMutable.badgesSkills
                              ? _c(
                                  "v-tooltip",
                                  {
                                    key: index,
                                    attrs: { top: "", "max-width": "50em" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on, attrs }) {
                                            return [
                                              _c(
                                                "v-chip",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "mr-1 my-1 chips",
                                                      attrs: {
                                                        density: "compact",
                                                      },
                                                    },
                                                    "v-chip",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(badge.technology) +
                                                      "\n                  "
                                                  ),
                                                  _c("span", {
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.getStarSkills(
                                                          badge.skill
                                                        )
                                                      ),
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _vm._v(" "),
                                    _c("span", [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(_vm.getTitleBadge(badge.skill))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("p", { attrs: { color: "#ECECEF" } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getInfoBadge(
                                              badge.skill,
                                              badge.id
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              : _vm._e()
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.stemdoerMutable.certifications.length != 0
                ? _c("hr", {
                    staticClass: "mx-3",
                    staticStyle: { "margin-top": "-1em" },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.stemdoerMutable.certifications.length != 0
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mx-3",
                              staticStyle: { "margin-top": "-0.3em" },
                            },
                            [
                              _c("h5", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "explore.stemdoer-detail.certifications"
                                    )
                                  )
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { staticClass: "mx-3 my-3" },
                            _vm._l(
                              _vm.stemdoerMutable.certifications,
                              function (badge, index) {
                                return _c(
                                  "v-chip",
                                  {
                                    key: index,
                                    staticClass: "mr-1 chips",
                                    staticStyle: { "margin-top": "0.2em" },
                                    attrs: { density: "compact" },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(badge.name) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", {
            staticClass: "header-content",
            domProps: {
              innerHTML: _vm._s(_vm.$t("explore.stemdoer-detail.experience")),
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "firstBox" },
            [
              _c(
                "v-col",
                { staticClass: "pl-3 pt-3", attrs: { cols: "12" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "mx-3 my-3" },
                    _vm._l(
                      _vm.stemdoerMutable.experience,
                      function (experience, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "exp-container" },
                          [
                            experience &&
                            experience.experiences &&
                            experience.experiences.length > 1
                              ? _c(
                                  "div",
                                  { staticClass: "ma-0" },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "pa-0" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "ma-0 pa-0 d-flex",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: {
                                                  bottom: "",
                                                  "content-class":
                                                    "background-darken3 tooltip-bottom",
                                                  "nudge-bottom": "-26",
                                                  "nudge-right": "6",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({
                                                        on,
                                                        attrs,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            _vm._g(
                                                              _vm._b(
                                                                {},
                                                                "span",
                                                                attrs,
                                                                false
                                                              ),
                                                              on
                                                            ),
                                                            [
                                                              _c(
                                                                "v-sheet",
                                                                {
                                                                  staticClass:
                                                                    "mr-2",
                                                                  staticStyle: {
                                                                    "border-radius":
                                                                      "3px",
                                                                    "font-size":
                                                                      "17px",
                                                                    padding:
                                                                      "0.2em 0em 0em 0.35em",
                                                                  },
                                                                  attrs: {
                                                                    color:
                                                                      "#f6f6f6",
                                                                    elevation:
                                                                      "0",
                                                                    width:
                                                                      "35px",
                                                                    height:
                                                                      "35px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "💼\n                          "
                                                                  ),
                                                                  _vm.isStemdoExp(
                                                                    experience.company
                                                                  )
                                                                    ? _c(
                                                                        "img",
                                                                        {
                                                                          staticClass:
                                                                            "verfication-mark",
                                                                          attrs:
                                                                            {
                                                                              src: "/content/svgs/stemdo-verified.svg",
                                                                              alt: "",
                                                                              width:
                                                                                "20px",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _vm._v(" "),
                                                _c("span", {
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.$t(
                                                        "explore.stemdoer-detail.verify"
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "position pa-0",
                                                  staticStyle: {
                                                    "margin-top": "-0.4em",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        experience.company
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "total-time pa-0 text-capitalize mb-7",
                                                  staticStyle: {
                                                    "margin-top": "-1.2em",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm
                                                          .getTotalTime(
                                                            experience.totalTime
                                                          )
                                                          .toString()
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    experience &&
                                    experience.experiences &&
                                    experience.experiences.length > 1
                                      ? _c(
                                          "v-timeline",
                                          {
                                            staticClass: "timeline",
                                            attrs: { dense: "" },
                                          },
                                          _vm._l(
                                            experience.experiences,
                                            function (
                                              experienceCompany,
                                              index
                                            ) {
                                              return _c(
                                                "v-timeline-item",
                                                {
                                                  key: index,
                                                  attrs: {
                                                    small: "",
                                                    color: "#DBDBE0",
                                                    "fill-dot": true,
                                                    density: "compact",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          display: "flex",
                                                          "margin-top":
                                                            "-12px !important",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "p",
                                                          {
                                                            staticClass:
                                                              "title-position d-flex align-center",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  experienceCompany.title
                                                                ) +
                                                                "\n                        "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-body-3 pl-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.sectorFormatted(
                                                                        experienceCompany.sector
                                                                      )
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        experienceCompany.language &&
                                                        experienceCompany.language !=
                                                          "SPANISH"
                                                          ? _c(
                                                              "sLanguageIcon",
                                                              {
                                                                attrs: {
                                                                  language:
                                                                    experienceCompany.language,
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      { staticClass: "date" },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                experienceCompany.initDate
                                                              )
                                                            ) +
                                                            " -\n                      " +
                                                            _vm._s(
                                                              _vm.formatDate(
                                                                experienceCompany.endDate
                                                              ) ??
                                                                _vm.$t(
                                                                  "explore.stemdoer-detail.present"
                                                                )
                                                            ) +
                                                            "\n                    "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("br"),
                                                    _vm._v(" "),
                                                    _vm.getTech(
                                                      experienceCompany.technologies
                                                    ).length > 0
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "mt-3 d-flex flex-wrap",
                                                            staticStyle: {
                                                              "row-gap": "10px",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.getTech(
                                                              experienceCompany.technologies
                                                            ),
                                                            function (
                                                              tech,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                { key: index },
                                                                [
                                                                  _c(
                                                                    "v-chip",
                                                                    {
                                                                      staticClass:
                                                                        "mr-1 chips",
                                                                      attrs: {
                                                                        density:
                                                                          "compact",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          tech
                                                                        )
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      [
                                                        _c("br"),
                                                        _vm._v(" "),
                                                        _c("SReadMore", {
                                                          staticStyle: {
                                                            "margin-left":
                                                              "-2px",
                                                          },
                                                          attrs: {
                                                            text: experienceCompany.description,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]),
                                                ]
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            experience &&
                            experience.experiences &&
                            experience.experiences.length == 1 &&
                            experience.experiences[0]
                              ? _c(
                                  "div",
                                  { staticClass: "ma-0" },
                                  [
                                    _c(
                                      "v-row",
                                      { staticClass: "pa-0" },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            staticClass: "ma-0 pa-0 d-flex",
                                            attrs: { cols: "12" },
                                          },
                                          [
                                            _c(
                                              "v-sheet",
                                              {
                                                staticClass: "mr-2",
                                                staticStyle: {
                                                  "border-radius": "3px",
                                                  "font-size": "17px",
                                                  padding:
                                                    "0.2em 0em 0em 0.35em",
                                                },
                                                attrs: {
                                                  color: "#f6f6f6",
                                                  elevation: "0",
                                                  width: "35px",
                                                  height: "35px",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "💼\n                    "
                                                ),
                                                experience &&
                                                experience.experiences &&
                                                _vm.isStemdoExp(
                                                  experience.experiences[0]
                                                    .company
                                                )
                                                  ? _c("img", {
                                                      staticClass:
                                                        "verfication-mark",
                                                      attrs: {
                                                        src: "/content/svgs/stemdo-verified.svg",
                                                        alt: "",
                                                        width: "20px",
                                                      },
                                                    })
                                                  : _vm._e(),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("div", [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "margin-top": "-0.2em",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "p",
                                                    {
                                                      staticClass:
                                                        "position pa-0 d-flex align-center",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            experience
                                                              .experiences[0]
                                                              .title
                                                          ) +
                                                          "\n                        "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "text-body-3 pl-1",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.sectorFormatted(
                                                                experience
                                                                  .experiences[0]
                                                                  .sector
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  experience.experiences[0]
                                                    .language &&
                                                  experience.experiences[0]
                                                    .language != "SPANISH"
                                                    ? _c("sLanguageIcon", {
                                                        staticStyle: {
                                                          "margin-top":
                                                            "-0.2em",
                                                        },
                                                        attrs: {
                                                          language:
                                                            experience
                                                              .experiences[0]
                                                              .language,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "company pa-0 text-capitalize",
                                                  staticStyle: {
                                                    "margin-top": "-1.2em",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        experience.company
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "date",
                                        staticStyle: { "margin-left": "-1em" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.formatDate(
                                                experience.experiences[0]
                                                  .initDate
                                              )
                                            ) +
                                            " -\n                " +
                                            _vm._s(
                                              _vm.formatDate(
                                                experience.experiences[0]
                                                  .endDate
                                              ) ??
                                                _vm.$t(
                                                  "explore.stemdoer-detail.present"
                                                )
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _vm.getTech(
                                      experience.experiences[0].technologies
                                    ).length > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "mt-3 d-flex flex-wrap",
                                            staticStyle: {
                                              "margin-left": "-1.2em",
                                              "row-gap": "10px",
                                            },
                                          },
                                          _vm._l(
                                            _vm.getTech(
                                              experience.experiences[0]
                                                .technologies
                                            ),
                                            function (tech, index) {
                                              return _c(
                                                "div",
                                                { key: index },
                                                [
                                                  _c(
                                                    "v-chip",
                                                    {
                                                      staticClass: "mr-1 chips",
                                                      attrs: {
                                                        density: "compact",
                                                      },
                                                    },
                                                    [_vm._v(_vm._s(tech))]
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: { "margin-left": "-1em" },
                                      },
                                      [
                                        _c("br"),
                                        _vm._v(" "),
                                        _c("SReadMore", {
                                          staticStyle: {
                                            "margin-left": "-2px",
                                          },
                                          attrs: {
                                            text: experience.experiences[0]
                                              .description,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.stemdoerMutable.experience.length - 1 != index
                              ? _c("hr", {
                                  staticClass: "mt-4 mb-6",
                                  staticStyle: {
                                    "margin-left": "-1em",
                                    width: "100%",
                                  },
                                })
                              : _vm._e(),
                          ]
                        )
                      }
                    ),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("p", { staticClass: "header-content" }, [
            _vm._v(_vm._s(_vm.$t("explore.stemdoer-detail.education"))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "firstBox mt-7" },
            [
              _c(
                "v-col",
                { staticClass: "pl-3 pt-3 d-flex", attrs: { cols: "12" } },
                [
                  _c(
                    "v-sheet",
                    {
                      staticClass: "pa-2 mr-2",
                      staticStyle: {
                        "border-radius": "3px",
                        "font-size": "16px",
                      },
                      attrs: {
                        color: "#f6f6f6",
                        elevation: "0",
                        width: "35px",
                        height: "35px",
                      },
                    },
                    [_vm._v("🎓")]
                  ),
                  _vm._v(" "),
                  _c("p", { staticClass: "pa-0 grade" }, [
                    _vm._v(
                      _vm._s(_vm.stemdoerMutable.education.replace(";", " "))
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }