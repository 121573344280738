import { render, staticRenderFns } from "./SModalCentered.vue?vue&type=template&id=207b7009&scoped=true"
import script from "./sModalCentered.ts?vue&type=script&lang=ts&external"
export * from "./sModalCentered.ts?vue&type=script&lang=ts&external"
import style0 from "./SModalCentered.vue?vue&type=style&index=0&id=207b7009&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "207b7009",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/r1/a/_stemdoplatform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('207b7009')) {
      api.createRecord('207b7009', component.options)
    } else {
      api.reload('207b7009', component.options)
    }
    module.hot.accept("./SModalCentered.vue?vue&type=template&id=207b7009&scoped=true", function () {
      api.rerender('207b7009', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/components/stemdo-components/s-modal-centered/SModalCentered.vue"
export default component.exports