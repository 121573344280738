var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "mx-0 mb-8 p-0 mt-3",
      attrs: { id: "no-content-team", fluid: "" },
    },
    [
      _c("v-col", { attrs: { cols: "12" } }, [
        _c(
          "div",
          { staticClass: "menu" },
          [
            _c("v-row", [
              _c("strong", {
                domProps: {
                  textContent: _vm._s(_vm.$t("no-content-team.title-text")),
                },
              }),
            ]),
            _vm._v(" "),
            _c("v-row", [
              _c("p", {
                staticClass: "mb-3 p-1",
                staticStyle: { width: "55%", margin: "auto" },
                domProps: {
                  textContent: _vm._s(_vm.$t("no-content-team.description")),
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "v-row",
              [
                _c("s-button", {
                  staticStyle: {
                    background:
                      "linear-gradient(75deg, #4237ff 0%, #9747ff 100%)",
                  },
                  attrs: {
                    sText: "no-content-team.button-text",
                    action: _vm.redirectToExplore,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticStyle: {
              position: "relative",
              width: "100%",
              height: "100%",
            },
          },
          [
            _c("div", {
              staticStyle: {
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(0deg, rgba(255, 255, 255, 1) 16.5%, rgba(0, 194, 177, 0) 100%)",
                "z-index": "1",
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "justify-content": "space-between",
                  width: "100%",
                },
              },
              _vm._l(3, function (index) {
                return _c("v-sheet", {
                  key: index,
                  staticClass: "mb-1",
                  staticStyle: { "z-index": "0" },
                  attrs: {
                    height: "205px",
                    width: "32.5%",
                    rounded: "",
                    color: "#F9F9F9",
                  },
                })
              }),
              1
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }