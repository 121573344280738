import { defineComponent, inject, onMounted, ref } from 'vue';
import SAvatarStemdoer from '@/components/stemdo-components/s-avatar-stemdoer/SAvatarStemdoer.vue';
import TeamRequestStatus from './team-request-status/TeamRequestStatus.vue';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import { useStore } from '@/plugins/vuex';
import ResourceService from '@/shared/ResourceService.service';

export default defineComponent({
  props: {
    requestSelected: {
      type: Object,
      required: true,
    },
    closeRequestDetailModal: {
      type: Function,
    },
    refreshTeamRequestData: {
      type: Function,
    },
  },
  components: {
    SAvatarStemdoer,
    TeamRequestStatus,
  },
  setup(props) {
    const resourceService: ResourceService = inject('resourceService');

    const activeStep = ref('');
    const loading = ref(false);
    const store = useStore();

    const generatePDFListStemdoers = () => {
      const arrayStemdoers = ref([]);
      const listIdMongo = ref('');
      const ratesList = ref({});

      if (props.requestSelected.status === RequestStatus.SAVED) {
        arrayStemdoers.value = props.requestSelected.stemdoers;
        listIdMongo.value = arrayStemdoers.value.map(stemdoer => stemdoer.id).join(',');
      } else {
        arrayStemdoers.value = props.requestSelected.stemdoerRates;
        listIdMongo.value = arrayStemdoers.value.map(stemdoerRates => stemdoerRates.stemdoer.id).join(',');
        ratesList.value = arrayStemdoers.value.reduce((acc, stemdoerRates) => {
          acc[stemdoerRates.stemdoer.id] = stemdoerRates.rate;
          return acc;
        }, {});
      }

      const clientName = store.getters['client'] ? store.getters['client'].name : '';
      const clientSurname = store.getters['client'] ? store.getters['client'].surname : '';
      const formattedNameClient =
        clientName && clientSurname
          ? `${clientName.charAt(0).toLocaleUpperCase()}${clientName.slice(1)} ${clientSurname.substring(0, 1).toLocaleUpperCase()}.`
          : '';

      const myData = {
        isTeam: true,
        ratesList: ratesList.value,
        stemdoerShowBadge: {},
        initDate: props.requestSelected.initDate,
        endDate: props.requestSelected.endDate,
        locale: store.getters.currentLanguage,
        paramId: '',
        idClient: store.getters['client'] ? store.getters['client'].id : '',
        nameClient: formattedNameClient,
      };

      const myJSON = JSON.stringify(myData);
      resourceService.generatePDF(listIdMongo.value, myJSON);
    };

    onMounted(() => {
      activeStep.value = props.requestSelected.status;
    });

    const stepperStatus = () => {
      if (props.requestSelected.status == RequestStatus.PENDING) {
        return 0;
      } else if (props.requestSelected.status == RequestStatus.VALIDATED) {
        return 1;
      } else if (
        props.requestSelected.status == RequestStatus.ON_GOING ||
        props.requestSelected.status == RequestStatus.CANCELLED_BY_MANAGER
      ) {
        return 2;
      } else if (props.requestSelected.status == RequestStatus.SAVED) {
        return 5;
      }
    };

    const startLoading = () => {
      loading.value = true;
      generatePDFListStemdoers();
      setTimeout(() => {
        loading.value = false;
      }, 5000);
    };

    return { loading, activeStep, stepperStatus, startLoading };
  },
});
