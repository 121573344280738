var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "m-0 px-8", attrs: { id: "explore", fluid: "" } },
    [
      _c(
        "v-row",
        {
          staticStyle: { "align-items": "baseline" },
          attrs: { "no-gutters": "" },
        },
        [
          _c(
            "v-col",
            { staticClass: "py-3 ma-0", attrs: { cols: "8", fluid: "" } },
            [
              _c("h3", {
                attrs: { id: "title-page" },
                domProps: { textContent: _vm._s(_vm.$t("explore.title-page")) },
              }),
            ]
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pa-0 ma-0 text-right", attrs: { cols: "4" } },
            [
              _c("BtnMyTeam", {
                attrs: {
                  cartTotalItems: _vm.cartTotalItems,
                  isClientSelected: _vm.isClientSelected,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "flex-nowrap" },
        [
          _c(
            "v-col",
            { attrs: { cols: 12 } },
            [
              _c("TopFilter", {
                attrs: {
                  stemdoers: _vm.stemdoers,
                  rates: _vm.rates,
                  isManager: _vm.isManager,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c("v-col"),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              !_vm.isStemdoersListEmpty
                ? _c("SwitchAvailability", {
                    attrs: {
                      filterField: _vm.FilterFields.Available,
                      action: _vm.changeAvailableFilter,
                      tempFilters: false,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.displaySkeleton
        ? _c(
            "v-row",
            [_vm.displaySkeleton ? [_c("StemdoerListSkeleton")] : _vm._e()],
            2
          )
        : !_vm.isLoading &&
          !_vm.displaySkeleton &&
          _vm.visibleStemdoers.length > 0
        ? _c(
            "v-row",
            [
              _c("StemdoerList", {
                attrs: {
                  stemdoers: _vm.visibleStemdoers,
                  addStemdoerToCart: _vm.addStemdoerToCart,
                  rates: _vm.rates,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isStemdoersListEmpty
        ? _c(
            "v-row",
            {
              staticClass: "fadeIn align-center justify-center",
              attrs: { "no-gutters": "" },
            },
            [[_c("EmptyStemdoerList")]],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }