/* eslint-disable prettier/prettier */
import { PropType, defineComponent, ref, inject, onMounted, watch } from 'vue';
import { useStore } from '@/plugins/vuex';
import { FilterFields } from '../model/Filters';
import { IAtocompleteMultipleState } from '@/components/stemdo-components/s-autocomplete-multiple/sAutocompleteMultiple';
import { IAtocompleteMultipleRatingState } from '@/components/stemdo-components/s-autocomplete-multiple-rating/sAutocompleteMultipleRating';
import CertificationService from '@/entities/certification/certification.service';
import { Certification } from '@/shared/model/certification.model';
import { IRate } from '@/shared/model/rate.model';
import { IRangeSliderStepsRateState } from '@/components/stemdo-components/s-range-slider-steps-rate/sRangeSliderStepsRate';
import CheckboxEnglish from './checkbox-english/CheckboxEnglish.vue';
import { IAtocompleteState } from '@/components/stemdo-components/s-autocomplete/sAutocomplete';

export default defineComponent({
  components: {
    CheckboxEnglish,
  },
  props: {
    techItems: {
      type: Array as PropType<IAtocompleteMultipleState[]>,
      required: true,
    },
    compItems: {
      type: Array as PropType<IAtocompleteMultipleRatingState[]>,
      required: true,
    },
    softSkillsItems: {
      type: Array as PropType<IAtocompleteMultipleState[]>,
      required: true,
    },
    rates: {
      type: Array as () => IRate[],
      required: true,
    },
    clickTechFilter: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const certificationService: CertificationService = inject('certificationService');
    const technologiesField = FilterFields.Technology;
    const competenciesField = FilterFields.Competency;
    const softSkillField = FilterFields.SoftSkill;
    const certField = FilterFields.Certification;
    const rateField = FilterFields.Rate;
    const langField = FilterFields.Language;
    const certItems = ref<IAtocompleteMultipleState[]>([]);

    onMounted(() => {
      loadCertifications();
    });

    const loadCertifications = () => {
      certificationService.retrieve().then(res => {
        const certifications: Certification[] = Certification.toCertifications(res.data);
        certifications.forEach(certification => {
          certItems.value.push({ id: certification.id, desc: certification.name });
        });
      });
    };

    const changeTechFilter = (field: FilterFields, newSelectedItem: IAtocompleteState) => {
      const payload = { field, newSelectedItem };
      store.commit('filterStore/updateAutoCompleteTempState', payload);
      props.clickTechFilter(field, newSelectedItem, true);
    };

    const changeCompFilter = (field: FilterFields, newSelectedItem: IAtocompleteMultipleRatingState) => {
      const payload = { field, newSelectedItem };
      store.commit('filterStore/updateAutoCompleteMultipleRatingTempState', payload);
    };

    const changeSoftSkillFilter = (field: FilterFields, newSelectedItems: IAtocompleteMultipleState[]) => {
      const payload = { field, newSelectedItems };
      store.commit('filterStore/updateAutoCompleteMultipleTempState', payload);
    };

    const changeCertFilter = (field: FilterFields, newSelectedItems: IAtocompleteMultipleState[]) => {
      const payload = { field, newSelectedItems };
      store.commit('filterStore/updateAutoCompleteMultipleTempState', payload);
    };

    const changeRateFilter = (field: FilterFields, newRateRange: IRangeSliderStepsRateState) => {
      const payload = { field, newRateRange };
      store.commit('filterStore/updateRangeSliderStepsRateTempState', payload);
    };

    const changeLangFilter = (field: FilterFields, newVal: boolean) => {
      const payload = { field, newVal };
      store.commit('filterStore/updateCheckboxTempState', payload);
    };

    return {
      certItems,
      technologiesField,
      competenciesField,
      softSkillField,
      certField,
      rateField,
      langField,
      changeTechFilter,
      changeCompFilter,
      changeSoftSkillFilter,
      changeCertFilter,
      changeRateFilter,
      changeLangFilter,
    };
  },
});
