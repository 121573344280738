import { useRouter } from '@/plugins/router';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter();

    const redirectToExplore = () => {
      router.push('/explore');
    };
    return { redirectToExplore };
  },
});
