import { defineComponent, computed } from 'vue';
import SAvatarStemdoer from '@/components/stemdo-components/s-avatar-stemdoer/SAvatarStemdoer.vue';
import { useI18N } from '@/plugins/i18n';
import { RequestStatus } from '@/shared/model/enumerations/request-status.model';
import { StatusFilter } from '../filter-card/filterCard';

export default defineComponent({
  props: {
    teamRequestDisplayed: {
      type: Array,
      required: true,
    },
    openRequestDetailModal: {
      type: Function,
    },
    openCancelModal: {
      type: Function,
    },
    statusFilterSelected: {
      type: String,
      required: true,
    },
  },
  components: {
    SAvatarStemdoer,
  },
  setup(props) {
    const i18n = useI18N();
    const headers = computed(() => [
      { text: i18n.t('requests.name'), value: 'name', width: '33%' },
      { text: i18n.t('requests.my-team'), value: 'stemdoers', width: '13%' },
      { text: i18n.t('requests.created'), value: 'createdAt', width: '13%' },
      { text: i18n.t('requests.rate'), value: 'hourlyRate', width: '15%' },
      { text: i18n.t('requests.status.title'), value: 'status', width: '13%' },
      { text: '', value: 'action', width: '1%' },
    ]);

    const handleClickDetail = item => {
      props.openRequestDetailModal(item);
    };
    const getStatusDetails = (status: string) => {
      let border = '#ECECEF';
      let background = '#F9F9F9';
      let translation = '';

      switch (status) {
        case RequestStatus.ON_GOING:
          border = '#00C2B1';
          background = '#E5F9F7';
          translation = i18n.t('requests.detail.status.approved') as string;
          break;
        case RequestStatus.PENDING:
          border = '#FFA337';
          background = '#FFF6EB';
          translation = i18n.t('requests.detail.status.pending') as string;
          break;
        case RequestStatus.VALIDATED:
          border = '#FFA337';
          background = '#FFF6EB';
          translation = i18n.t('requests.detail.status.validated') as string;
          break;
        case RequestStatus.CANCELLED_BY_MANAGER:
          border = '#EF2630';
          background = '#FFEBEC';
          translation = i18n.t('requests.detail.status.rejected') as string;
          break;
        case RequestStatus.SAVED:
          border = '#8859FF';
          background = '#F3EEFF';
          translation = i18n.t('requests.detail.status.saved') as string;
          break;
        default:
          break;
      }
      return { border, background, translation };
    };

    const noDataMsg = () => {
      switch (props.statusFilterSelected) {
        case StatusFilter.Approved:
          return i18n.t('requests.detail.no-data.approved') as string;
        case StatusFilter.Pending:
          return i18n.t('requests.detail.no-data.pending') as string;
        case StatusFilter.Rejected:
          return i18n.t('requests.detail.no-data.rejected') as string;
        case StatusFilter.Saved:
          return i18n.t('requests.detail.no-data.saved') as string;
      }
    };

    return {
      RequestStatus,
      headers,
      getStatusDetails,
      handleClickDetail,
      noDataMsg,
    };
  },
});
