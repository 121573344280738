var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { attrs: { id: "stemdoer-card" } }, [
    _c("div", { staticClass: "pt-2" }, [
      _c(
        "div",
        {
          staticClass:
            "d-flex align-items-start justify-space-between pa-0 mx-6 mb-0",
        },
        [
          _c(
            "div",
            { staticClass: "info-box" },
            [
              _c("v-row", { staticClass: "pa-0 ma-0" }, [
                _c(
                  "div",
                  { staticClass: "stemdoer-card__img mt-6" },
                  [
                    _c("SAvatarStemdoer", {
                      staticStyle: {
                        height: "40px !important",
                        width: "40px !important",
                      },
                      attrs: { stemdoId: _vm.stemdoer.stemdoId, size: "40px" },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "stemdoer-card__info ml-3 mt-3" }, [
                  _c("p", { staticClass: "text-body-1" }, [
                    _vm._v(
                      _vm._s(
                        _vm.stemdoer.name +
                          " " +
                          _vm.stemdoer.surname.charAt(0) +
                          "."
                      )
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "text-body-1-bold" }, [
                    _vm._v(_vm._s(_vm.stemdoer.position)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "pa-0 ma-0" },
                _vm._l(_vm.stemdoer.technologyScores, function (tech, index) {
                  return index < 4
                    ? _c("v-chip", { key: index, staticClass: "mr-2" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "stemdoPlatformApp.technology.list." +
                                tech.idTechnology
                            )
                          )
                        ),
                      ])
                    : _vm._e()
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-column align-items-end" }, [
            _c(
              "div",
              { staticStyle: { height: "22px" } },
              [
                _vm.onDelete !== undefined
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "mt-1 cursor-pointer delete-btn",
                        attrs: { icon: "", outlined: "" },
                        on: {
                          click: (e) => _vm.onDeleteClick(_vm.stemdoer.id),
                        },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: "/content/svgs/close-grey.svg",
                            alt: "Delete",
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex flex-column toolbox mt-3" }, [
              _c("div", { staticClass: "pa-0 ma-0" }, [
                _c("p", {
                  staticClass: "cost-text mb-3",
                  domProps: {
                    textContent: _vm._s(
                      _vm.$t("managerTeamDetail.hourly-rate")
                    ),
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex pa-0 ma-0" },
                [
                  _c("SInputNumber", {
                    attrs: { value: _vm.rate, onChange: _vm.onRateChange },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "btn-data ml-2" },
                    [
                      _c("v-img", {
                        staticStyle: { transform: "scale(0.8)" },
                        attrs: { width: 22, src: "/content/svgs/data.svg" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }