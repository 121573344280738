import { render, staticRenderFns } from "./ItemContent.vue?vue&type=template&id=57fa1c5c&scoped=true"
import script from "./ItemContent.vue?vue&type=script&lang=ts"
export * from "./ItemContent.vue?vue&type=script&lang=ts"
import style0 from "./ItemContent.vue?vue&type=style&index=0&id=57fa1c5c&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57fa1c5c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/vsts/work/r1/a/_stemdoplatform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('57fa1c5c')) {
      api.createRecord('57fa1c5c', component.options)
    } else {
      api.reload('57fa1c5c', component.options)
    }
    module.hot.accept("./ItemContent.vue?vue&type=template&id=57fa1c5c&scoped=true", function () {
      api.rerender('57fa1c5c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/main/webapp/app/components/main-drawer/main-drawer-item/ItemContent.vue"
export default component.exports